.app {
  text-align: center;
  height: 100vh;
}

.logo {
  height: 40vh;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .logo {
    animation: logoSpin infinite 20s linear;
  }
}

.header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vh);
  color: white;
}

@keyframes logoSpin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.nav{
  display: flex;
  justify-content: space-between;
  background: #1C1A4E;
  padding: 2vh 3%;
  height: 3vh;
  align-items: center;
  
}

.menu{
  height: 96vh;
  width: 100%;
  background-color: rgba(28, 26, 78, 0.9);
  position: absolute;
  z-index: 1;

  ul{
    padding:5%;
    li{
      border-bottom: 2px solid #006DFE;
      padding-bottom: 10%;
      span,a{
        font-family: Lato-Regular;
        font-size: 1rem;
        font-weight: normal;
        text-align: center;

        color: #FFFFFF;
      }
    }
    li:nth-child(n+1){
      margin-top:10%;
    }
  }
  
}

.mobile-content-card{
  background-image: url('./assets/mobile-content-bg.png');
  padding-top: 10%;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 20%;

  // mask:  linear-gradient(180deg, rgba(28,26,78,0.30) 0%, #1C1A4E 81%);
}

.mobile-content-title{
  font-family: Lato-Bold;
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;

  color:#fff;
  margin-bottom: 5%;

}

.mobile-content-content{
  font-family: Lato-Regular;
  font-size: 1.2em;
  font-weight: normal;
  text-align: center;
  color: #FFFFFF;
}

.what-we-do-bg{
  background: #FFFFFF;
  padding: 0 3%;
}
.what-we-do-card{
  background: #FFFFFF;
  box-shadow: 0px 2px 20px 0px rgba(21, 24, 36, 0.16);
  position: relative;
  top:-5vh;
  padding: 5% 4%;
  padding-top: 10%;
}
.what-we-do-title{
  font-family: Lato-Black;
  font-size: 2rem;
  font-weight: 900;
  color: #102746;
  border-bottom:2px solid #006DFE;
  padding-bottom: 4%;
  display:inline-block;
}
.what-we-do-sub-list{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin:5% 0;
}
.what-we-do-sub-title{
  font-family: Lato-Black;
  font-size: 1.2rem;
  font-weight: 900;
  text-align: center;
  color: #000000;
  margin-top: 5%;
}

.what-we-do-sub-content{
  font-family: Lato-Regular;
  font-size: 1.2rem;
  font-weight: normal;
  text-align: center;
  color: #000000;
  margin-top: 2%;
}

.premium-content-card{
  padding: 10% 5%;
  background-color: #29276D;
}
.premium-content-title{
  font-family: Lato-Black;
  font-size: 1.5rem;
  font-weight: 900;
  text-align: center;
  color: #FFFFFF;
  display: inline-block;

  
}
.premium-content-line{
  width: 60%;
  height: 2px;
  background-color: #FFF;
  margin: auto;
  margin-top: 2%;
}

.premium-content-first-content{
  display: flex;
  margin-top: 5%;
  

  div{
    border-radius: 2rem;
    
    padding: 2% 5%;
    display: flex;
    flex-direction: column;
    flex-basis:calc(100% / 5);

    img{
      width: 40px;
      margin: auto;
    }

    span{
      font-family: Lato-Black;
      font-weight: 900;
     
      white-space: nowrap;
    }
  }
}

.premium-content{
  background-color: #34336A;
  span{
    color: rgba(255, 255, 255, 0.5);
  }
}

.premium-content-active{
  background-color: #F7C400;
  span{
    color: '#4E4ADB'
  }
}

.premium-content-bottom-content{
  display: flex;
  margin-top: 3%;
 p{
  font-family: Lato-Regular;
  font-size: 1rem;
  font-weight: normal;
  color: #F7C400;
  text-align: start;
  margin-left: 3%;
 }
}

.company-introduction{
  padding-top: 5%;
  padding-bottom: 5%;
  img{
    width: 100%;
  }
}

.company-introduction-title{
  font-family: Lato-Black;
    font-size: 1.5rem;
    font-weight: 900;
    color: #102746;
    display: inline-block;
    margin-top: 5%;

  div{
    background-color: #006DFE;
    height: 2px;
    width: 80%;
    margin: auto;
    margin-top: 5%;
    margin-bottom:8%;
  }
}

.company-introduction-content{
  padding: 0 5%;

  font-family: Lato-Regular;
  font-size: 1rem;
  font-weight: normal;
  text-align: center;

  color: #102746;
}

.company-introduction-carousel{
 li{
  
  button{
    background-color: blue !important;
    border-radius: 50% !important;
    width: 1rem !important;
    height: 1rem !important;
   
  }
  
 }
}

.make-money{
  padding: 10% 5%;
  background-color: #DDDCFF;
}

.make-money-title{
  font-family: Lato-Black;
  font-size: 1.5rem;
  font-weight: 900;
  text-align: center;
  color: #1C1A4E;
}

.make-money-line{
  height: 2px;
  background-color: #4E4ADB;
  width: 50%;
  margin: auto;
  margin-top: 3%;
  margin-bottom: 5%;
}

.make-money-content{
  font-family: Lato-Regular;
  font-weight: normal;
  text-align: center;
  color: #1C1A4E;
}

.make-money-button{
  background: #4E4ADB;
  border-radius: 1.5rem;
  border: 0;

  padding: 2% 3%;
  margin-top: 5%;

  font-family: Lato-Black;
  font-size: 1.5rem;
  font-weight: 900;
  color: #FFFFFF;
}

.get-in-touch{
  background-color: #29276D;
  padding: 10% 3%;
  padding-bottom: 5%;
}
.get-in-touch-title{
  font-family: Lato-Black;
  font-size: 24px;
  font-weight: 900;
  color: #FFFFFF;
  display:inline-block;
}
.get-in-touch-line{
  background-color: #6561FF;
  height: 1px;
  width: 80%;
  margin: auto;
  margin-top: 3%;
  margin-bottom: 10%;
}
.get-in-touch-content{
  font-family: Lato-Regular;
  font-size: 1.5rem;
  font-weight: normal;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 5%;
}
.get-in-touch-send{
  border-radius: 1.5rem;
  font-family: Lato-Black;
  font-size: 1.5rem;
  font-weight: 900;
  text-align: center;
  color: #1C1A4E;
  border: 0;
  background-color: #F7C400;
  padding: 2% 20%;

}
.footer{
  background-color: #1C1A4E;
  padding: 10% 0;
}
.footer-top-menu,.footer-bottom-menu{
 margin-top:5%;
  a{
  color:#FFF;
 }
 a:nth-child(2){
  margin:0 5%;
 }
}
.footer-cookie{
  margin-top: 5%;

  font-family: SourceSansPro-Regular;
  font-size: 12px;
  font-weight: normal;
  text-align: center;

  color: #FFFFFF;
}